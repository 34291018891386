import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-vendor-layout',
  templateUrl: './vendor-layout.component.html',
  styleUrls: ['./vendor-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorLayoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
