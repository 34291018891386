import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {AlertService} from '../services/alert.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationService.isLoggedIn() && !this.authenticationService.isTokenExpired()) {
      return true;
    } else if (this.authenticationService.isLoggedIn() && this.authenticationService.isTokenExpired()) {
      this.alertService.error('A munkamenet ideje lejárt, jelentkezz be újra!', 'HIBA');
      this.router.navigate(['/login']);
      return false;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
