import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {OpenDay, IsOpen, CloseDay} from '../models/day';

@Injectable({
  providedIn: 'root'
})
export class DayService {

  constructor(private http: HttpClient) {
  }

  openDay(openingCash: OpenDay) {
    return this.http.post(environment.apiUrl + 'open', openingCash);
  }

  isOpen(): Observable<IsOpen> {
    return this.http.get<IsOpen>(environment.apiUrl + 'utility/is_my_shop_open');
  }

  closeDay(closingCash: CloseDay) {
    return this.http.post(environment.apiUrl + 'close', closingCash);
  }

}
