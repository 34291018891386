import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../../services/alert.service';
import {ProductService} from '../../../services/product.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from '../../../helpers/formValidator';
import {Shop} from '../../../models/shop';
import {IsOpen} from '../../../models/day';
import {DayService} from '../../../services/day.service';

@Component({
  selector: 'app-product-registration',
  templateUrl: './product-registration.component.html'
})
export class ProductRegistrationComponent implements OnInit {

  productForm: FormGroup;
  submitted = false;
  shop: Shop;
  isOpen: boolean;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private dayService: DayService
  ) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.shop = currentUser.user.shop;
  }

  ngOnInit() {
    this.dayService.isOpen().subscribe((isOpen: IsOpen) => {
      this.isOpen = (isOpen.shop_open === 1);
    });
    this.createProductForm();
  }

  createProductForm() {
    this.productForm = this.formBuilder.group({
      name: ['', Validators.required],
      variant: ['', Validators.required],
      net: ['', Validators.required],
      gross: ['', Validators.required],
      sell_net: ['', Validators.required],
      sell_gross: ['', Validators.required],
      shop: [{value: this.shop.name, disabled: true}],
      barcode: ['', Validators.required]
    });
  }

  productValidation(property: string) {
    return FormValidator.ControlValidation(this.productForm, property, this.submitted);
  }

  addNewProduct() {
    this.submitted = true;
    if (this.productForm.invalid) {
      this.submitted = false;
      return;
    } else if (!this.isOpen) {
      this.alertService.error('A mai nap még nem lett megnyitva!', 'HIBA');
      return;
    } else {
      const product = {...this.productForm.value, shop_id: this.shop.id}
      this.productService.addProduct(product)
        .subscribe(() => {
          this.alertService.success('Termék hozzáadása sikeres', 'HOZZÁADVA');
          this.productForm.reset({shop: this.shop.name});
          this.submitted = false;
        });
    }
  }

  onClear() {
    this.productForm.reset({shop: this.shop.name});
    this.submitted = false;
  }

}
