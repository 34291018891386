import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.apiUrl + 'users');
  }

  addUser(user: User) {
    return this.http.post(environment.apiUrl + 'users', user);
  }

  updateUser(id: number, user: User) {
    return this.http.put(environment.apiUrl + 'users/' + id, user);
  }

  deleteUser(id: number) {
    return this.http.delete(environment.apiUrl + 'users/' + id);
  }

}
