import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Shop} from '../models/shop';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(private http: HttpClient) {
  }

  getShops(): Observable<Shop[]> {
    return this.http.get<Shop[]>(environment.apiUrl + 'shops');
  }

  addShop(shop: Shop)  {
    return this.http.post(environment.apiUrl + 'shops', shop);
  }

  updateShop(id: number, shop: Shop) {
    return this.http.put(environment.apiUrl + 'shops/' + id, shop);
  }

  deleteShop(id: number) {
    return this.http.delete(environment.apiUrl + 'shops/' + id);
  }

  fineDetails(shopID: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'utility/fineDetails/' + shopID);
  }

}
