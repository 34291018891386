import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-navigation-admin',
  templateUrl: './navigation-admin.component.html'
})
export class NavigationAdminComponent implements OnInit {

  loginDate: string;
  username: string;

  constructor(
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private datepipe: DatePipe,
    private router: Router
  ) {
    this.loginDate = this.datepipe.transform(localStorage.getItem('loginDate'), 'yyyy.MM.dd. HH:mm');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const user = currentUser && currentUser.user.username;
    this.username = user;
  }

  ngOnInit() {
  }

  onLogout() {
    this.authenticationService.logout().subscribe( () => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('loginDate');
      this.alertService.success('Sikeres kijelentkezés', 'Kilépés');
    });
    this.router.navigate(['/login']);
  }

}
