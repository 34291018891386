import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutsModule} from './components/layouts/layouts.module';
import {NgModule} from '@angular/core';
import {PagesModule} from './pages/pages.module';
import {ToastrModule} from 'ngx-toastr';

import {AppComponent} from './app.component';

import {AdminGuard} from './guards/admin.guard';
import {AlertService} from './services/alert.service';
import {AuthenticationService} from './services/authentication.service';
import {AuthGuard} from './guards/auth.guard';
import {DatePipe} from '@angular/common';
import {PagerService} from './services/pager.service';
import {ProductService} from './services/product.service';
import {SellService} from './services/sell.service';
import {ShopService} from './services/shop.service';
import {UserService} from './services/user.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {JwtInterceptor} from './interceptors/jwt.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    LayoutsModule,
    PagesModule,
    ToastrModule.forRoot({progressBar: true, timeOut: 2500, closeButton: true, positionClass: 'toast-bottom-right'}),
  ],
  providers: [
    AdminGuard,
    AlertService,
    AuthenticationService,
    AuthGuard,
    DatePipe,
    PagerService,
    ProductService,
    SellService,
    ShopService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
