import {Component, OnInit} from '@angular/core';
import {DayService} from '../../../services/day.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {IsOpen} from '../../../models/day';
import {CostService} from '../../../services/cost.service';

@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html'
})
export class CostComponent implements OnInit {

  costForm: FormGroup;
  isOpen: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private costService: CostService,
    private alertService: AlertService,
    private dayService: DayService
  ) {
  }

  ngOnInit() {
    this.dayService.isOpen().subscribe((isOpen: IsOpen) => this.isOpen = (isOpen.shop_open === 1));
    this.createCostForm();
  }

  createCostForm() {
    this.costForm = this.formBuilder.group({
      abandonee: ['', Validators.required],
      subject: ['', Validators.required],
      cost_price: ['', Validators.required]
    });
  }

  costRecording() {
    if (this.costForm.invalid) {
      return;
    } else if (!this.isOpen) {
      this.alertService.error('A mai nap még nem lett megnyitva!', 'HIBA');
      return;
    } else {
      this.costService.costRecording(this.costForm.value).subscribe(
        () => {
          this.alertService.success('Kiadás rögzítése sikeres.', 'SIKER');
          this.costForm.reset();
        },
        error => {
          this.alertService.error(error, 'HIBA');
        });
    }
  }

}
