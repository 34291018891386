import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../services/alert.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {FormValidator} from '../../helpers/formValidator';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;

  constructor(
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password)
        .subscribe( data => {
          if (data.user.admin) {
            this.router.navigate(['/dashboard']);
            this.alertService.success('Sikeres belépés', 'ADMIN');
          } else {
            this.router.navigate(['/day-open']);
            this.alertService.success('Sikeres belépés', data.user.username);
          }
        }, () => {
          // error
          this.loginForm.reset();
        });
    }
  }

  loginValidation(property: string) {
    return FormValidator.ControlValidation(this.loginForm, property, this.submitted);
  }

}
