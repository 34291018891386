import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AlertService} from '../services/alert.service';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authenticationService.isLoggedIn() && !this.authenticationService.isTokenExpired() && this.authenticationService.isAdmin()) {
      return true;
    } else if (this.authenticationService.isLoggedIn() && this.authenticationService.isTokenExpired()) {
      this.alertService.error('A munkamenet ideje lejárt, jelentkezz be újra!', 'HIBA');
      this.router.navigate(['/login']);
      return false;
    } else if (this.authenticationService.isLoggedIn() && !this.authenticationService.isAdmin()) {
      this.router.navigate(['/404']);
      return false;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
