import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html'
})
export class LogsComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  logs: any;

  constructor(
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: 'Nincs adat az adatbázisban.',
        info: 'Találatok: _START_ - _END_ Összesen: _TOTAL_',
        infoEmpty: 'Nulla találat',
        infoFiltered: '(_MAX_ rekord közül szűrve)',
        lengthMenu: '_MENU_ találat oldalanként',
        loadingRecords: 'Betöltés...',
        processing: 'Feldolgozás...',
        search: 'Keresés:',
        zeroRecords: 'Nincs a keresésnek megfelelő találat',
        paginate: {
          first: 'Első',
          previous: 'Előző',
          next: 'Következő',
          last: 'Utolsó'
        }
      }
    };
    this.getLogs();
  }

  getLogs() {
    return this.http.get(environment.apiUrl + 'utility/logs').subscribe(
      (log: any[]) => {
        this.logs = log;
        this.dtTrigger.next();
      });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
