import {Component, OnInit} from '@angular/core';
import {DayService} from '../../../services/day.service';
import {IsOpen} from '../../../models/day';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-day-close',
  templateUrl: './day-close.component.html'
})
export class DayCloseComponent implements OnInit {

  loading = true;
  closingCashControl = new FormControl(null, Validators.required);
  isClose = false;
  isOpen: boolean;
  closingCash: number;

  constructor(private dayService: DayService) {
  }

  ngOnInit() {
    this.dayService.isOpen().subscribe((isOpen: IsOpen) => {
      this.isOpen = (isOpen.shop_open === 1);
      this.loading = false;
    });
  }

  closeDay(): void {
    this.closingCash = this.closingCashControl.value.toString().replace(/ /g, '');
    const estimated = {estimated: this.closingCash};
    this.dayService.closeDay(estimated).subscribe( () => {
      this.isClose = true;
      localStorage.removeItem('openingDetails');
    });
  }

}
