import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {ProductService} from '../../../services/product.service';
// import { debounceTime } from 'rxjs/operators';
import {Product} from '../../../models/product';
import {IsOpen} from '../../../models/day';
import {DayService} from '../../../services/day.service';

@Component({
  selector: 'app-take-in',
  templateUrl: './take-in.component.html'
})
export class TakeInComponent implements OnInit {

  takeInForm: FormGroup;
  products: Product[];
  productName: string = 'Termék neve';
  productId: number;
  isOpen: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private productService: ProductService,
    private dayService: DayService
  ) {
  }

  ngOnInit() {
    this.dayService.isOpen().subscribe((isOpen: IsOpen) => this.isOpen = (isOpen.shop_open === 1));
    this.getProductsGivenShop();
    this.createTakeInForm();
  }

  getProductsGivenShop() {
    this.productService.getProductsGivenShop().subscribe((products: Array<Product>) => {
      this.products = products;
    });
  }

  createTakeInForm() {
    this.takeInForm = this.formBuilder.group({
      barcode: ['', Validators.required]
    });
    /*
        this.takeInForm.controls.barcode.valueChanges.pipe(
          debounceTime(300)
        ).subscribe((barcode) => {
          if (barcode != '') {
            const product = this.products.find(product => product.barcode === barcode);
            if (product) {
              this.productName = product.name;
            } else {
              this.alertService.error('Nincs termék a rendszerben ilyen vonalkóddal!', 'HIBA');
              this.resetForm();
            }
          } else {
            this.resetForm(true);
          }
        });*/
  }

  lostFocus() {
    const barcode = this.takeInForm.controls.barcode.value;
    if (barcode !== '') {
      const product = this.products.find(product => product.barcode === barcode);
      if (product) {
        this.productName = product.name;
        this.productId = product.id;
      } else {
        this.alertService.error('Nincs termék a rendszerben ilyen vonalkóddal!', 'HIBA');
        this.resetForm();
      }
    } else {
      this.resetForm();
    }
  }

  takeIn() {
    if (!this.isOpen) {
      this.alertService.error('A mai nap még nem lett megnyitva!', 'HIBA');
      return;
    }
    this.productService.takeInProduct(this.productId)
      .subscribe(response => {
        this.alertService.success('Termék sikeresen bevételezve.', 'SIKER');
        this.resetForm();
      });
  }

  resetForm() {
    this.productId = null;
    this.productName = 'Termék neve';
    // this.takeInForm.controls.productName.setValue('');
    // if (resetBarcode) {
    //   this.takeInForm.controls.barcode.setValue('');
    // }
    this.takeInForm.reset();
  }

}
