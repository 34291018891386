import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {AlertService} from '../services/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        location.reload(true);
      }

      let errorMessage = '';
      if (err.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${err.error.message}`;
      } else {
        // server-side error
        // errorMessage = err.error.errors[Object.keys(err.error.errors)[0]][0];
        errorMessage = err.error.error;
        const url = err.url;
        if (err.status === 422) {
          this.alertService.error('Ilyen mező már szerepel az adatbázisban!', 'HIBA');
        } else {
          this.alertService.error(errorMessage, 'HIBA!');
        }
      }

      return throwError(errorMessage);
    }));
  }
}
