import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Shop} from '../../../models/shop';
import {User} from '../../../models/user';
import {AlertService} from '../../../services/alert.service';
import {FormValidator} from '../../../helpers/formValidator';
import {ShopService} from '../../../services/shop.service';
import {UserService} from '../../../services/user.service';

declare let jQuery: any;

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html'
})
export class AdministrationComponent implements OnInit {

  adminForm: FormGroup;
  shopForm: FormGroup;
  userForm: FormGroup;

  shops: Array<Shop>;
  users: Array<User>;

  selectedAdmin: User;
  selectedUser: User;
  selectedShop: Shop;

  submitted = false;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private shopService: ShopService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.shopService.getShops().subscribe((shops: Array<Shop>) => this.shops = shops);
    this.userService.getUsers().subscribe((users: Array<User>) => this.users = users);
    this.createAdminForm();
    this.createUserForm();
    this.createShopForm();
  }

  // FORMS
  createAdminForm() {
    this.adminForm = this.formBuilder.group({
      activated: [true],
      username: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: FormValidator.MatchPassword
    });
  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      activated: [true, Validators.required],
      product_allowed: [true, Validators.required],
      shop_id: [null],
      username: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: FormValidator.MatchPassword
    });
  }

  createShopForm() {
    this.shopForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  // ADMINS
  adminValidation(property: string) {
    return FormValidator.ControlValidation(this.adminForm, property, this.submitted);
  }

  addNewAdmin() {
    this.submitted = true;
    if (this.adminForm.invalid) {
      this.submitted = false;
      return;
    } else {
      this.adminForm.value.admin = 1;
      this.adminForm.value.product_allowed = 1;
      this.userService.addUser(this.adminForm.value)
        .subscribe((admin: User) => {
            this.alertService.success('Adminisztrátor hozzáadása sikeres', 'HOZZÁADVA');
            this.users.push(admin);
            this.adminForm.reset();
            this.submitted = false;
            jQuery('#addAdmin').modal('hide');
          }
        );
    }
  }

  onSelectedAdmin(admin: User) {
    this.adminForm.reset();
    this.adminForm.get('password').setValidators(null);
    this.adminForm.get('confirmPassword').setValidators(null);
    this.adminForm.setValue({
      activated: admin.activated,
      username: admin.username,
      password: '',
      confirmPassword: ''
    });
    this.selectedAdmin = admin;
  }

  updateAdmin() {
    this.submitted = true;
    const updatedAdminForm = FormValidator.getModifiedFields(this.adminForm);
    if (this.adminForm.invalid || !Object.entries(updatedAdminForm).length) {
      this.submitted = false;
      return;
    } else {
      this.userService.updateUser(
        this.selectedAdmin.id, FormValidator.createUpdatedFormGroup(updatedAdminForm, this.formBuilder).value)
        .subscribe(
          (admin: User) => {
            this.alertService.success('Admin szerkesztése sikeres', 'SZERKESZTVE');
            const updatedAdminId = this.users.findIndex((res: User) => res.id === this.selectedAdmin.id);
            this.users[updatedAdminId] = admin;
            this.adminForm.reset();
            this.adminForm.get('password').setValidators(Validators.required);
            this.adminForm.get('confirmPassword').setValidators(Validators.required);
            this.submitted = false;
          });
    }
  }

  onDeleteAdmin(id: number) {
    const removedAdminId = this.users.findIndex((admin: User) => admin.id === id);
    this.userService.deleteUser(id).subscribe(
      () => {
        this.users.splice(removedAdminId, 1);
        this.alertService.success('Sikeresen törölte az admint.', 'TÖRLÉS');
      });
  }

  // USERS
  userValidation(property: string) {
    return FormValidator.ControlValidation(this.userForm, property, this.submitted);
  }

  addNewUser() {
    this.submitted = true;
    if (this.userForm.invalid) {
      this.submitted = false;
      return;
    } else {
      this.userForm.value.admin = false;
      this.userService.addUser(this.userForm.value)
        .subscribe((user: User) => {
            this.alertService.success('Felhasználó hozzáadása sikeres', 'HOZZÁADVA');
            this.users.push(user);
            this.userForm.reset();
            this.shopService.getShops().subscribe((shops: Array<Shop>) => this.shops = shops);
            this.submitted = false;
            jQuery('#addUser').modal('hide');
          }
        );
    }
  }

  onSelectedUser(user: User) {
    this.userForm.reset();
    this.userForm.get('password').setValidators(null);
    this.userForm.get('confirmPassword').setValidators(null);
    this.userForm.setValue({
      activated: user.activated,
      product_allowed: user.product_allowed,
      username: user.username,
      password: '',
      confirmPassword: '',
      shop_id: user.shop && user.shop.id
    });
    this.selectedUser = user;
  }

  updateUser() {
    this.submitted = true;
    const updatedUserForm = FormValidator.getModifiedFields(this.userForm);
    if (this.userForm.invalid || !Object.entries(updatedUserForm).length) {
      this.submitted = false;
      return;
    } else {
      this.userService.updateUser(
        this.selectedUser.id, FormValidator.createUpdatedFormGroup(updatedUserForm, this.formBuilder).value)
        .subscribe(
          (user: User) => {
            this.alertService.success('Felhasználó szerkesztése sikeres', 'SZERKESZTVE');
            const updatedUserId = this.users.findIndex((res: User) => res.id === this.selectedUser.id);
            this.users[updatedUserId] = user;
            this.userForm.reset();
            this.userForm.get('password').setValidators(Validators.required);
            this.userForm.get('confirmPassword').setValidators(Validators.required);
            this.submitted = false;
          });
    }
  }

  onDeleteUser(id: number) {
    const removedUserId = this.users.findIndex((user: User) => user.id === id);
    this.userService.deleteUser(id).subscribe(
      () => {
        this.users.splice(removedUserId, 1);
        this.alertService.success('Sikeresen törölte a felhasználót.', 'TÖRLÉS');
      });
  }

  // SHOPS
  shopValidation(property: string) {
    return FormValidator.ControlValidation(this.shopForm, property, this.submitted);
  }

  addNewShop() {
    this.submitted = true;
    if (this.shopForm.invalid) {
      this.submitted = false;
      return;
    } else {
      this.shopService.addShop(this.shopForm.value).subscribe(
        (shop: Shop) => {
          this.alertService.success('Bolt hozzáadása sikeres', 'HOZZÁADVA');
          this.shops.push(shop);
          this.shopForm.reset();
          this.submitted = false;
        });
    }
  }

  onSelectedShop(shop: Shop) {
    this.shopForm.setValue({
      name: shop.name
    });
    this.selectedShop = shop;
  }

  updateShop() {
    this.submitted = true;
    const updatedShopForm = FormValidator.getModifiedFields(this.shopForm);
    if (this.shopForm.invalid || !Object.entries(updatedShopForm).length) {
      this.submitted = false;
      return;
    } else {
      this.shopService.updateShop(
        this.selectedShop.id, FormValidator.createUpdatedFormGroup(updatedShopForm, this.formBuilder).value)
        .subscribe(
          (shop: Shop) => {
            this.alertService.success('Bolt szerkesztése sikeres', 'SZERKESZTVE');
            const updatedShopId = this.shops.findIndex((res: Shop) => res.id === this.selectedShop.id);
            this.shops[updatedShopId] = shop;
            this.shopForm.reset();
            this.submitted = false;
          });
    }
  }

  onDeleteShop(id: number) {
    const removedShopId = this.shops.findIndex((shop: Shop) => shop.id === id);
    this.shopService.deleteShop(id).subscribe(
      () => {
        this.shops.splice(removedShopId, 1);
        this.alertService.success('Sikeresen törölte a boltot.', 'TÖRLÉS');
      });
  }

  onClear() {
    this.adminForm.get('password').setValidators(Validators.required);
    this.adminForm.get('confirmPassword').setValidators(Validators.required);
    this.userForm.get('password').setValidators(Validators.required);
    this.userForm.get('confirmPassword').setValidators(Validators.required);
    this.shopForm.reset();
    this.userForm.reset();
    this.adminForm.reset();
  }

}
