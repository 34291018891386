import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../../services/alert.service';
import {ShopService} from '../../../services/shop.service';
import {CostService} from '../../../services/cost.service';
import {UserService} from '../../../services/user.service';
import {Shop} from '../../../models/shop';

declare let jQuery: any;

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html'
})
export class CostsComponent implements OnInit {

  enableFilter = false;

  days: Array<any>;
  shops: Array<any>;
  users: Array<any>;
  pages: Array<any>;
  paginationObject: any;

  currentShop: any;
  filter: any = { perPage: 10};
  newProperties: any = {};

  constructor(
    private costService: CostService,
    private userService: UserService,
    private alertService: AlertService,
    private shopService: ShopService,
  ) {
    this.shopService.getShops().subscribe((shops: Array<Shop>) => {
      this.shops = shops;
      this.currentShop = shops[0];
      this.getAllCost();
    });

    this.userService.getUsers().subscribe(users => this.users = users);

    // Ezt nem látja senki, egy kis ES5-ES6 csúnyaság
    (Array as any).prototype.sum = function(prop) {
      let total = 0;
      for (let i = 0, len = this.length; i < len; i++) {
        total += this[i][prop];
      }
      return total;
    };
  }

  ngOnInit() {
  }

  getAllCost(): void {
    this.costService.getAllCosts(this.currentShop.id).subscribe(
      (result) => {
        this.paginationObject = result;
        this.days = result.data;
        this.calculatePages();
      }
    );
  }

  updateCost(): void {
    this.costService.updateCost(this.newProperties.id, {
      abandonee: this.newProperties.abandonee,
      subject: this.newProperties.subject,
      cost_price: this.newProperties.cost_price,
    }).subscribe(() => {
      this.getAllCost();
      jQuery('#editRow').modal('hide');
      this.alertService.success('Sor módosítva.', 'SIKER');
    });
  }

  deleteCost(): void {
    this.costService.deleteCost(this.newProperties.id).subscribe( () => {
      this.getAllCost();
      jQuery('#deleteRow').modal('hide');
      this.alertService.success('Kiadás törölve.', 'SIKER');
    });
  }

  markCost(id, dayId): void {
    const cost = this.days.find((day: any) => day.id === dayId).costs.find((inc: any) => inc.id === id);
    cost.marked = !cost.marked;
    this.costService.markCost(id).subscribe();
  }

  doFilter(): void {
    console.log(this.filter);
    this.costService.getFilteredCosts(this.currentShop.id, this.filter).subscribe(
      (result) => {
        this.paginationObject = result;
        this.days = result.data;
        this.calculatePages();
      }
    );
  }

  changeShop(id, index): void {
    this.currentShop = this.shops[index];
    this.costService.getAllCosts(this.currentShop.id).subscribe(
      (result) => {
        this.paginationObject = result;
        this.days = result.data;
        this.calculatePages();
      }
    );
  }

  selectedRow(newProperties: any) {
    this.newProperties = newProperties;
  }

  /** UI related methods */

  showFilters() {
    this.enableFilter = true;
  }

  hideFilters() {
    this.enableFilter = false;
  }

  /** Pagination related methods */

  calculatePages() {
    this.pages = [];
    if (this.paginationObject.prev_page_url !== null) {

      this.pages[0] = {
        number: this.paginationObject.current_page - 1,
        url: this.paginationObject.prev_page_url
      };

      this.pages[1] = {
        number: this.paginationObject.current_page,
        url: null
      };

      if (this.paginationObject.next_page_url !== null) {

        this.pages[2] = {
          number: this.paginationObject.current_page + 1,
          url: this.paginationObject.next_page_url
        };

      }

    } else {

      this.pages[0] = {
        number: this.paginationObject.current_page,
        url: null
      };

      if (this.paginationObject.next_page_url !== null) {

        this.pages[1] = {
          number: this.paginationObject.current_page + 1,
          url: this.paginationObject.next_page_url
        };

      }

    }
  }

  first() {
    if (this.paginationObject.current_page !== 1) {
      this.costService.getAllCosts(this.currentShop.id)
        .subscribe(
          success => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  previous() {
    if (this.paginationObject.prev_page_url !== null) {
      this.costService.getPaginatedCosts(this.paginationObject.prev_page_url, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  next() {
    if (this.paginationObject.next_page_url !== null) {
      this.costService.getPaginatedCosts(this.paginationObject.next_page_url, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  last() {
    if (this.paginationObject.next_page_url !== null) {
      this.costService.getPaginatedCosts(this.paginationObject.path + '?page=' + this.paginationObject.last_page, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  paginate(url: string) {
    if (url !== null) {
      this.costService.getPaginatedCosts(url, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

}
