import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Product} from '../models/product';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private http: HttpClient) {
    }

    getProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(environment.apiUrl + 'products');
    }

    getProductsGivenShop(): Observable<Product[]> {
        const shopID = JSON.parse(localStorage.getItem('currentUser')).user.shop.id;
        return this.http.get<Product[]>(environment.apiUrl + 'products/shop/' + shopID);
    }

    getProductsByShop(shopID: number): Observable<Product[]> {
        return this.http.get<Product[]>(environment.apiUrl + 'products/shop/' + shopID);
    }

    addProduct(product: Product) {
        return this.http.post(environment.apiUrl + 'products', product);
    }

    updateProduct(id: number, product: Product) {
        return this.http.put(environment.apiUrl + 'products/' + id, product);
    }

    deleteProduct(id: number) {
        return this.http.delete(environment.apiUrl + 'products/' + id);
    }

    findProduct(barcode: number): Observable<any> {
        return this.http.post<any>(environment.apiUrl + 'products/find', {barcode: barcode});
    }

    takeInProduct(productID: number): Observable<any> {
        return this.http.post<any>(environment.apiUrl + 'utility/take_in/' + productID, null);
    }

    markProduct(id: number): Observable<any> {
        return this.http.put(environment.apiUrl + 'products/' + id + '/mark', null);
    }

    shopDetails(shopID: number): Observable<any> {
        return this.http.get(environment.apiUrl + 'utility/details/' + shopID);
    }

}
