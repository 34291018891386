import {Component, OnInit} from '@angular/core';
import {DayService} from '../../../services/day.service';
import {OpenDay} from '../../../models/day';
import {FormControl, Validators} from '@angular/forms';
import {IsOpen} from '../../../models/day';

@Component({
  selector: 'app-day-open',
  templateUrl: './day-open.component.html'
})
export class DayOpenComponent implements OnInit {

  public openingCashControl = new FormControl(null, Validators.required);
  public isOpen: boolean;
  public loading = true;
  public openingCash: number;

  constructor(
    private dayService: DayService
  ) {
  }

  ngOnInit() {
    this.dayService.isOpen().subscribe((isOpen: IsOpen) => {
      this.isOpen = (isOpen.shop_open === 1);
      if (this.isOpen) {
        const openingDetails = JSON.parse(localStorage.getItem('openingDetails'));
        this.openingCash = openingDetails.opening_cash;
      } else {
        this.openingCashControl.enable();
      }
      this.loading = false;
    });
  }

  public openDay() {
    this.openingCash = this.openingCashControl.value.toString().replace(/ /g, '');
    const data: OpenDay = {opening_cash: this.openingCash};
    this.dayService.openDay(data).subscribe((openingDetails: any) => {
      this.isOpen = true;
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      currentUser.user.shop.opened = 1;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      localStorage.setItem('openingDetails', JSON.stringify(openingDetails));
    });
  }

}
