import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';

export class FormValidator {

  static MatchPassword(AC: AbstractControl) {

    const password = AC.get('password').value;
    const confirmPassword = AC.get('confirmPassword').value;

    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors({MatchPassword: true});
    } else {
      return null;
    }

  }

  static ControlValidation(fg: FormGroup, property: string, submitted: boolean): string {
    if (fg.controls[property].dirty || fg.controls[property].touched || submitted) {
      return fg.controls[property].valid ? 'is-valid' : 'is-invalid';
    }
  }

  static getModifiedFields(fg: FormGroup) {
    const modified = {};
    Object.keys(fg.controls).forEach(c => {
      const actualControl = fg.get(c);

      if (actualControl.dirty) {
        modified[c] = actualControl.value;
      }
    });
    return modified;
  }

  static createUpdatedFormGroup(updatedForm: any, fb: FormBuilder): FormGroup {
    return fb.group({
      ...updatedForm,
    });
  }

}
