import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Product} from '../../../models/product';
import {AlertService} from '../../../services/alert.service';
import {ProductService} from '../../../services/product.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from '../../../helpers/formValidator';
import {ShopService} from '../../../services/shop.service';
import {Shop} from '../../../models/shop';
import {PagerService} from '../../../services/pager.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html'
})
export class ProductsComponent implements OnInit, AfterViewInit {

  pager: any = {};
  pagedProducts: Array<Product>;
  itemPerPage: number;

  shops: Array<Shop>;
  currentShop: any;

  productForm: FormGroup;
  products: Array<Product>;
  selectedProduct: Product;

  shopDetails: any;

  searchString: string;

  submitted = false;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private pagerService: PagerService,
    private productService: ProductService,
    private shopService: ShopService,
  ) {
  }

  ngOnInit() {
    this.createProductForm();
  }

  ngAfterViewInit(): void {
    this.shopService.getShops().subscribe((shops: Array<Shop>) => {
      this.shops = shops;
      this.currentShop = shops[0];
      this.getProductsByShop();
      this.getShopDetails();
    });
  }

  getProductsByShop(): void {
    this.productService.getProductsByShop(this.currentShop.id).subscribe((products: Array<Product>) => {
      this.products = products;
      this.itemPerPage = 99999;
      this.setPage(1);
    });
  }

  getShopDetails(): void {
    this.productService.shopDetails(this.currentShop.id).subscribe( (shopDetails: any) => {
      this.shopDetails = shopDetails;
    });
  }

  createProductForm() {
    this.productForm = this.formBuilder.group({
      name: ['', Validators.required],
      variant: ['', Validators.required],
      net: ['', Validators.required],
      gross: ['', Validators.required],
      sell_net: ['', Validators.required],
      sell_gross: ['', Validators.required],
      shop_id: ['', Validators.required],
      barcode: ['', Validators.required],
      // amount: ['', Validators.required]
    });
  }

  productValidation(property: string) {
    return FormValidator.ControlValidation(this.productForm, property, this.submitted);
  }

  changeShop(shopID, index): void {
    this.currentShop = this.shops[index];
    this.getProductsByShop();
    this.getShopDetails();
  }

  addNewProduct() {
    this.submitted = true;
    if (this.productForm.invalid) {
      this.submitted = false;
      return;
    } else {
      this.productForm.value.amount = 0;
      this.productService.addProduct(this.productForm.value)
        .subscribe( (product: Product) => {
          this.alertService.success('Termék hozzáadása sikeres', 'HOZZÁADVA');
          this.products.push(product);
          this.productForm.reset();
          this.submitted = false;
          this.setPage(this.itemPerPage);
        });
    }
  }

  onSelectedProduct(product: Product) {
    this.productForm.setValue({
      name: product.name,
      variant: product.variant,
      net: product.net,
      gross: product.gross,
      sell_net: product.sell_net,
      sell_gross: product.sell_gross,
      shop_id: product.shop.id,
      barcode: product.barcode,
      // amount: product.amount
    });
    this.selectedProduct = product;
  }

  updateProduct() {
    this.submitted = true;
    const updatedProductForm = FormValidator.getModifiedFields(this.productForm);
    if (this.productForm.invalid || !Object.entries(updatedProductForm).length) {
      this.productForm.reset();
      this.submitted = false;
      return;
    } else {
      this.productService.updateProduct(
        this.selectedProduct.id, FormValidator.createUpdatedFormGroup(updatedProductForm, this.formBuilder).value)
        .subscribe(
          (product: Product) => {
            this.alertService.success('Termék szerkesztése sikeres', 'SZERKESZTVE');
            const updatedProductId = this.products.findIndex((res: Product) => res.id === this.selectedProduct.id);
            this.products[updatedProductId] = product;
            this.productForm.reset();
            this.submitted = false;
            this.setPage(this.itemPerPage);
          });
    }
  }

  onDeleteProduct(id: number) {
    const removedProductId = this.products.findIndex((product: Product) => product.id === id);
    this.productService.deleteProduct(id).subscribe(
      () => {
        this.products.splice(removedProductId, 1);
        this.alertService.success('Sikeresen törölte a terméket.', 'TÖRLÉS');
      });
  }

  markProduct(id: number): void {
    this.productService.markProduct(id).subscribe(() => {
      this.productService.getProductsByShop(this.currentShop.id).subscribe((products: Array<Product>) => {
        this.products = products;
        this.setPage(this.itemPerPage);
      });
    });
  }

  onClear() {
    this.productForm.reset();
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.products.length, page, this.itemPerPage);

    // get current page of items
    this.pagedProducts = this.products.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

}
