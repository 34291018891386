import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Selling} from '../models/product';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SellService {

  constructor(private http: HttpClient) {
  }

  getLatestSell(): Observable<any> {
    return this.http.get(environment.apiUrl + 'sells');
  }

  sellProduct(selling: Selling): Observable<any> {
    return this.http.post(environment.apiUrl + 'sell', selling);
  }

  updateSell(id: number, sellPrice: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'sells/' + id, sellPrice);
  }

  deleteSell(id: number) {
    return this.http.delete(environment.apiUrl + 'sells/' + id);
  }

  getAllSells(shopID: number, filter: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'sells/' + shopID, filter);
  }

  getFilteredSells(shopID: number, filter: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'sells/' + shopID, filter);
  }

  getPaginatedSells(url: string, filter: any): Observable<any> {
    return this.http.post(url, filter);
  }

  markSell(id: number): Observable<any> {
    return this.http.put(environment.apiUrl + 'sells/' + id + '/mark', null);
  }

}
