import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Cost} from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class CostService {

  constructor(private http: HttpClient) {
  }

  getLatestCost(): Observable<any> {
    return this.http.get(environment.apiUrl + 'costs');
  }

  getAllCosts(id: number): Observable<any> {
    return this.http.post(environment.apiUrl + 'costs/' + id, null);
  }

  getFilteredCosts(id: number, filter: any): Observable<any> {
    return this.http.post( environment.apiUrl + 'costs/' + id, filter);
  }

  costRecording(cost: Cost): Observable<Cost> {
    return this.http.post<Cost>(environment.apiUrl + 'cost', cost);
  }

  updateCost(id: number, cost: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'costs/' + id, cost);
  }

  deleteCost(id: number) {
    return this.http.delete(environment.apiUrl + 'costs/' + id);
  }

  getPaginatedCosts(url: string, filter: any): Observable<any> {
    return this.http.post(url, filter);
  }

  markCost(id: number): Observable<any> {
    return this.http.put(environment.apiUrl + 'costs/' + id + '/mark', null);
  }


}
