import {Component, OnInit} from '@angular/core';
import {SellService} from '../../../services/sell.service';
import {ShopService} from '../../../services/shop.service';
import {UserService} from '../../../services/user.service';
import {User} from '../../../models/user';
import {Shop} from '../../../models/shop';
import {AlertService} from '../../../services/alert.service';

declare let jQuery: any;

@Component({
  selector: 'app-sells',
  templateUrl: './sells.component.html'
})
export class SellsComponent implements OnInit {

  enableFilter = false;

  currentShop: any;
  days: Array<any>;
  filter: any = {perPage: 10};
  paginationObject: any;
  pages: Array<any>;
  shops: Array<Shop>;
  users: Array<User>;

  sellId: number;
  sellPrice: number;

  constructor(
    private alertService: AlertService,
    private sellService: SellService,
    private shopService: ShopService,
    private userService: UserService
  ) {
    this.shopService.getShops().subscribe((shops: Array<Shop>) => {
      this.shops = shops;
      this.currentShop = shops[0];
      this.getAllSell();
    });
    this.userService.getUsers().subscribe(users => this.users = users);

    // Ezt nem látja senki, egy kis ES5-ES6 csúnyaság
    (Array as any).prototype.sum = function(prop) {
      let total = 0;
      for (let i = 0, len = this.length; i < len; i++) {
        total += this[i][prop];
      }
      return total;
    };
  }

  ngOnInit() {
  }

  /** Logic */

  getAllSell(): void {
    this.sellService.getAllSells(this.currentShop.id, this.filter).subscribe(
      (result) => {
        this.paginationObject = result;
        this.days = result.data;
        this.calculatePages();
      }
    );
  }

  updateSell(): void {
    this.sellService.updateSell(this.sellId, {sell_price: this.sellPrice}).subscribe(() => {
      this.getAllSell();
      jQuery('#editRow').modal('hide');
      this.alertService.success('Sor módosítva.', 'SIKER');
    });
  }

  deleteSell(): void {
    this.sellService.deleteSell(this.sellId).subscribe( () => {
      this.getAllSell();
      jQuery('#deleteRow').modal('hide');
      this.alertService.success('Eladás törölve.', 'SIKER');
    });
  }

  markSell(id, dayId): void {
    const sell = this.days.find((day: any) => day.id === dayId).sells.find((inc: any) => inc.id === id);
    sell.marked = !sell.marked;
    this.sellService.markSell(id).subscribe();
  }

  doFilter(): void {
    this.sellService.getFilteredSells(this.currentShop.id, this.filter).subscribe(
      (result) => {
        this.paginationObject = result;
        this.days = result.data;
        this.calculatePages();
      }
    );
  }

  changeShop(id, index): void {
    this.currentShop = this.shops[index];
    this.sellService.getAllSells(this.currentShop.id, this.filter).subscribe(
      (result) => {
        this.paginationObject = result;
        this.days = result.data;
        this.calculatePages();
      }
    );
  }

  selectedRow(sellID: number, sellPrice: number) {
    this.sellId = sellID;
    this.sellPrice = sellPrice;
  }

  /** UI related methods */

  showFilters() {
    this.enableFilter = true;
  }

  hideFilters() {
    this.enableFilter = false;
  }

  /** Pagination related methods */

  calculatePages() {
    this.pages = [];
    if (this.paginationObject.prev_page_url !== null) {
      this.pages[0] = {
        number: this.paginationObject.current_page - 1,
        url: this.paginationObject.prev_page_url
      };

      this.pages[1] = {
        number: this.paginationObject.current_page,
        url: null
      };

      if (this.paginationObject.next_page_url !== null) {

        this.pages[2] = {
          number: this.paginationObject.current_page + 1,
          url: this.paginationObject.next_page_url
        };
      }
    } else {
      this.pages[0] = {
        number: this.paginationObject.current_page,
        url: null
      };

      if (this.paginationObject.next_page_url !== null) {

        this.pages[1] = {
          number: this.paginationObject.current_page + 1,
          url: this.paginationObject.next_page_url
        };
      }
    }
  }

  first() {
    if (this.paginationObject.current_page !== 1) {
      this.sellService.getAllSells(this.currentShop.id, this.filter)
        .subscribe(
          success => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  previous() {
    if (this.paginationObject.prev_page_url !== null) {
      this.sellService.getPaginatedSells(this.paginationObject.prev_page_url, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  next() {
    if (this.paginationObject.next_page_url !== null) {
      this.sellService.getPaginatedSells(this.paginationObject.next_page_url, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  last() {
    if (this.paginationObject.next_page_url !== null) {
      this.sellService.getPaginatedSells(this.paginationObject.path + '?page=' + this.paginationObject.last_page, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }

  paginate(url: string) {
    if (url !== null) {
      this.sellService.getPaginatedSells(url, this.filter)
        .subscribe(
          (success) => {
            this.paginationObject = success;
            this.days = success.data;
            this.calculatePages();
          });
    }
  }


}
