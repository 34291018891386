import {AfterContentInit, Component} from '@angular/core';
import {User} from '../../../models/user';
import {UserService} from '../../../services/user.service';
import {SellService} from '../../../services/sell.service';
import {CostService} from '../../../services/cost.service';
import {Shop} from '../../../models/shop';
import {ShopService} from '../../../services/shop.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements AfterContentInit {

  users: Array<User>;
  sells: Array<any>;
  costs: Array<any>;
  shops: Array<Shop>;

  currentShop: any;
  fineDetails: any = '';

  constructor(
    private userService: UserService,
    private sellService: SellService,
    private costService: CostService,
    private shopService: ShopService
  ) {
  }

  ngAfterContentInit() {
    this.userService.getUsers().subscribe((users: Array<User>) => this.users = users);
    this.sellService.getLatestSell().subscribe((sells: Array<any>) => this.sells = sells);
    this.costService.getLatestCost().subscribe((costs: Array<any>) => {
      this.costs = costs;
    });
    this.shopService.getShops().subscribe((shops: Array<Shop>) => {
      this.shops = shops;
      this.currentShop = shops[0].id;
      this.getFineDetails();
    });
  }

  changeShop(event: any): void {
    this.currentShop = event.target.value;
    this.getFineDetails();
  }

  getFineDetails(): void {
    this.shopService.fineDetails(this.currentShop).subscribe( (fineDetails: any) => {
      this.fineDetails = fineDetails;
    });

  }

}
