import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {DataTablesModule} from 'angular-datatables';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {Currency} from '../helpers/currencyDirective';

import {AdministrationComponent} from './admin/administration/administration.component';
import {CostsComponent} from './admin/costs/costs.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import {DayCloseComponent} from './vendor/day-close/day-close.component';
import {DayOpenComponent} from './vendor/day-open/day-open.component';
import {CostComponent} from './vendor/cost/cost.component';
import {SellsComponent} from './admin/sells/sells.component';
import {LoginComponent} from './login/login.component';
import {LogsComponent} from './admin/logs/logs.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {ProductRegistrationComponent} from './vendor/product-registration/product-registration.component';
import {ProductsComponent} from './admin/products/products.component';
import {SellComponent} from './vendor/sell/sell.component';
import {TakeInComponent} from './vendor/take-in/take-in.component';

import {ThousandSeparatorPipe} from '../pipes/thousand-separator.pipe';
import {FilterPipe} from '../pipes/filter.pipe';

@NgModule({
  declarations: [
    AdministrationComponent,
    CostsComponent,
    DashboardComponent,
    DayCloseComponent,
    DayOpenComponent,
    CostComponent,
    SellsComponent,
    LoginComponent,
    LogsComponent,
    PageNotFoundComponent,
    ProductRegistrationComponent,
    ProductsComponent,
    SellComponent,
    TakeInComponent,
    Currency,
    ThousandSeparatorPipe,
    FilterPipe
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    AdministrationComponent,
    CostsComponent,
    DashboardComponent,
    DayCloseComponent,
    DayOpenComponent,
    CostComponent,
    SellsComponent,
    LoginComponent,
    LogsComponent,
    PageNotFoundComponent,
    ProductRegistrationComponent,
    ProductsComponent,
    SellComponent,
    TakeInComponent,
  ]
})
export class PagesModule {
}
