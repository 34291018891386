import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';

import {AdminLayoutComponent} from './admin-layout.component';
import {DefaultLayoutComponent} from './default-layout.component';
import {NavigationAdminComponent} from '../navigation-admin/navigation-admin.component';
import {NavigationVendorComponent} from '../navigation-vendor/navigation-vendor.component';
import {VendorLayoutComponent} from './vendor-layout.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    DefaultLayoutComponent,
    NavigationAdminComponent,
    NavigationVendorComponent,
    VendorLayoutComponent
  ],
  imports: [
    BrowserModule,
    RouterModule
  ],
  exports: [
    AdminLayoutComponent,
    DefaultLayoutComponent,
    NavigationAdminComponent,
    NavigationVendorComponent,
    VendorLayoutComponent
  ]
})
export class LayoutsModule {
}
