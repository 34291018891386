import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  backHome() {
    if (!this.authenticationService.isLoggedIn()) {
      return this.router.navigate(['/login']);
    } else if (this.authenticationService.isAdmin()) {
      return this.router.navigate(['/dashboard']);
    } else {
      return this.router.navigate(['/day-open']);
    }
  }

}
