import {Directive, Input, Output, EventEmitter, OnInit, ElementRef} from '@angular/core';

@Directive({
    selector: '[currency]',
    host: {
        '[value]': 'currency',
        '(input)': 'format($event.target.value)'
    }
})
export class Currency implements OnInit {

    @Input() currency: number;
    @Output() currencyChange: EventEmitter<number> = new EventEmitter<number>();

    constructor(public el: ElementRef) {
        this.el.nativeElement.onkeypress = (evt) => {
            if (evt.which < 48 || evt.which > 57) {
                evt.preventDefault();
            }
        };
    }

    ngOnInit() {
        this.currency = this.currency || null;
        if (this.currency) {
            this.format(this.currency);
        }
    }

    thousandSeparator(currency) {
        currency = currency.toString().replace(/ /g, '');
        return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    format(value) {
        value = this.thousandSeparator(value);
        this.currencyChange.next(value);
    }
}