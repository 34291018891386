import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {ProductService} from '../../../services/product.service';
// import { debounceTime } from 'rxjs/operators';
import {Product} from '../../../models/product';
import {DayService} from '../../../services/day.service';
import {IsOpen} from '../../../models/day';
import {SellService} from '../../../services/sell.service';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html'
})
export class SellComponent implements OnInit {

  sellForm: FormGroup;
  productName: string = 'Termék neve';
  variant: string = 'Variáció';
  productId: number;
  products: Product[];
  isOpen: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private productService: ProductService,
    private dayService: DayService,
    private sellService: SellService
  ) {
  }

  ngOnInit() {
    this.dayService.isOpen().subscribe((isOpen: IsOpen) => this.isOpen = (isOpen.shop_open === 1));
    this.getProductsGivenShop();
    this.createSellForm();
  }

  getProductsGivenShop() {
    this.productService.getProductsGivenShop().subscribe((products: Array<Product>) => {
      this.products = products;
    });
  }

  createSellForm() {
    this.sellForm = this.formBuilder.group({
      barcode: ['', Validators.required],
      sellPrice: ['', Validators.required]
    });
    /*
        this.sellForm.controls.barcode.valueChanges.pipe(
          debounceTime(300)
        ).subscribe((barcode) => {
          if (barcode != '') {
            const product = this.products.find(product => product.barcode === barcode);
            if (product) {
              this.productName = product.name;
              this.variant = product.variant;
              this.productId = product.id;
            } else {
              this.alertService.error('Nincs termék a rendszerben ilyen vonalkóddal!', 'HIBA');
              this.resetForm();
            }
          } else {
            this.resetForm(true);
          }
        });
    */
  }

  lostFocus() {
    const barcode = this.sellForm.controls.barcode.value;
    if (barcode !== '') {
      const product = this.products.find(product => product.barcode === barcode);
      if (product) {
        this.productName = product.name;
        this.variant = product.variant;
        this.productId = product.id;
      } else {
        this.alertService.error('Nincs termék a rendszerben ilyen vonalkóddal!', 'HIBA');
        this.resetForm();
      }
    } else {
      this.resetForm(true);
    }
  }

  resetForm(resetBarcode = false) {
    this.productName = 'Termék neve';
    this.variant = 'Variáció';
    this.productId = null;
    this.sellForm.controls.sellPrice.setValue('');
    if (resetBarcode) {
      this.sellForm.controls.barcode.setValue('');
    }
  }

  sell() {
    const selling = {
      product_id: this.productId,
      sell_price: this.sellForm.value.sellPrice.toString().replace(/ /g, '')
    };
    if (!this.isOpen) {
      this.alertService.error('A mai nap még nem lett megnyitva!', 'HIBA');
      return;
    }
    this.sellService.sellProduct(selling)
      .subscribe(response => {
        this.alertService.success('Termék sikeresen eladva.', 'ELADVA');
        this.resetForm(true);
      });
  }
}
