import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AdminLayoutComponent} from './components/layouts/admin-layout.component';
import {DefaultLayoutComponent} from './components/layouts/default-layout.component';
import {VendorLayoutComponent} from './components/layouts/vendor-layout.component';

// Guards
import {AdminGuard} from './guards/admin.guard';
import {AuthGuard} from './guards/auth.guard';

// Pages of default layout
import {LoginComponent} from './pages/login/login.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';

// Pages of admin layout
import {DashboardComponent} from './pages/admin/dashboard/dashboard.component';
import {ProductsComponent} from './pages/admin/products/products.component';
import {SellsComponent} from './pages/admin/sells/sells.component';
import {CostsComponent} from './pages/admin/costs/costs.component';
import {AdministrationComponent} from './pages/admin/administration/administration.component';
import {LogsComponent} from './pages/admin/logs/logs.component';

// Pages of vendor layout
import {DayOpenComponent} from './pages/vendor/day-open/day-open.component';
import {ProductRegistrationComponent} from './pages/vendor/product-registration/product-registration.component';
import {TakeInComponent} from './pages/vendor/take-in/take-in.component';
import {SellComponent} from './pages/vendor/sell/sell.component';
import {CostComponent} from './pages/vendor/cost/cost.component';
import {DayCloseComponent} from './pages/vendor/day-close/day-close.component';

const routes: Routes = [
  // Main redirect
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  // App views
  {
    path: '', component: DefaultLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {path: '404', component: PageNotFoundComponent},
    ]
  },
  // ADMIN
  {
    path: '', component: AdminLayoutComponent, canActivate: [AdminGuard],
    children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'products', component: ProductsComponent},
      {path: 'sells', component: SellsComponent},
      {path: 'costs', component: CostsComponent},
      {path: 'administration', component: AdministrationComponent},
      {path: 'logs', component: LogsComponent},
    ]
  },
  // VENDOR
  {
    path: '', component: VendorLayoutComponent, canActivate: [AuthGuard],
    children: [
      {path: 'day-open', component: DayOpenComponent},
      {path: 'product-registration', component: ProductRegistrationComponent},
      {path: 'take-in', component: TakeInComponent},
      {path: 'sell', component: SellComponent},
      {path: 'cost', component: CostComponent},
      {path: 'day-close', component: DayCloseComponent},
    ]
  },
  // Otherwise redirect to Page Not Found
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
